import { GoogleGenerativeAI } from "@google/generative-ai";
import './App.css';
import ArrowUpIcon from "./ArrowUpIcon";
import { useRef, useState } from "react";
import Code from "./Code";
import Text from './Text'

function App() {
  const [prompt, setPrompt] = useState("")
  const [result, setResult] = useState([])
  const genAI = new GoogleGenerativeAI(process.env.REACT_APP_API_KEY);
  const [isShowLoader, setIsShowLoader] = useState(false)
  const divRef = useRef();
  const formatResult = (str) => {
    const strArr = str.split(" ");
    let isCodeStarted = false;
    let values = []
    var output = []
    strArr.forEach((val) => {
      if (val.includes("```")) {
        const valArr = val.split("```");
        values = [...values, valArr[0]]
        output = [...output, { text: values?.join(" "), isCode: isCodeStarted }]
        values = [valArr[1]]
        isCodeStarted = !isCodeStarted
      } else {
        values = [...values, val]
      }
    })
    return output;
  }
  const handleSubmit = async (eve) => {
    try {
      eve.preventDefault();
      setIsShowLoader(true)
      divRef.current.style.height = '500px'
      scrolldiv()
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });
      const content = await model.generateContent(prompt);
      const response = await content.response;
      // const text = response.text();
      const formatedData = formatResult(response?.candidates[0]?.content?.parts[0]?.text)
      setResult([...result, { que: prompt, ans: formatedData }])
    }
    catch (ex) {
      console.error(ex);
    }
    finally {
      setPrompt("");
      setIsShowLoader(false);
      divRef.current.style.height = '0px'
    }
  }
  function scrolldiv() {
    window.scroll(0,
      findPosition(divRef.current));
  }
  function findPosition(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [currenttop];
    }
  }
  return (
    <div className="App">
      <h1 className="bg-primary text-center text-white py-1">Hi...Buddy</h1>
      <div className="px-3 mb-5">
        {
          result.map(({ que, ans }, ind) => {
            return <div key={`div_${ind}`}>
              <div className="mb-2">
                <b>Que: {que}</b>
              </div>
              <div className="mb-4 text-justify">
                <b>Ans:</b><pre>
                  {
                    ans.map(({ text, isCode }) => {
                      return isCode ? <Code program={text} /> : <Text text={text} />
                    })
                  }
                </pre>
              </div>
            </div>
          })
        }
        <div ref={divRef} className="text-start"> {isShowLoader && <img width="50px" alt="loading..." src="loading.gif" />}</div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="position-fixed bottom-0 w-100 mb-1">
          <input value={prompt} onChange={(eve) => setPrompt(eve.target.value)} placeholder="Enter prompt here..." className="form-control" />
          <button disabled={!prompt} className="arrow-up-btn" type="submit"><ArrowUpIcon /></button>
        </div>
      </form>
    </div>
  );
}

export default App;
