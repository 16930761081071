import React from 'react'

const Code = ({ program }) => {
    return (
        <div className='container-fluid row bg-info px-3 py-3' style={{ textWrap: 'balance' }}>
            <div className='col-12'>
                <code>
                    {program}
                </code>
            </div>
        </div>
    )
}

export default Code
