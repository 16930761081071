import React from 'react'

const Text = ({ text }) => {
    return (
        <div className='row container-fluid' style={{ textWrap: 'balance' }}>
            <div className='col-12'>
                {text}
            </div>
        </div>
    )
}

export default Text
